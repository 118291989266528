<template>
  <b-modal
    v-model="showModalSubscribe"
    id="subscribes-popup"
    modal-class="modal-add"
    :static="true"
    aria-labelledby="subscribes-popup-title"
  >
    <div slot="modal-header">
      <h4 id="subscribes-popup-title" class="text-center">
        Ой-ой, доступ закінчився!
      </h4>

      <button
        class="modal-add__close-btn"
        aria-label="Close Subscription Modal"
        @click="$bvModal.hide('subscribes-popup')"
      >
        <img
          src="@/assets/icons/modal-close.svg"
          alt=""
          width="28"
          height="28"
          aria-hidden="true"
        />
      </button>
    </div>

    <div slot="default">
      <div class="modal-add__img">
        <img
          src="@/assets/images/modal-subscription.webp"
          alt=""
          width="208"
          height="208"
        />
      </div>

      <div class="modal-add__subscription-text">
        <p>Поповніть його, щоб повернутися до пошуку!</p>
      </div>

      <div
        v-html="paymentForm"
        class="cabinet-pay-btn d-flex align-items-end justify-content-center"
      ></div>
    </div>

    <div slot="modal-footer"></div>
  </b-modal>
</template>

<script>
export default {
  name: 'SubscribesPopup.vue',
  computed: {
    showModalSubscribe: {
      set(val) {
        this.$store.commit('SUBSCRIBES', val);
      },
      get() {
        return this.$store.state.profile.subscribe;
      }
    },
    paymentForm() {
      const paymentForms = this.$store.state.profile.userMe.payment_forms;
      if (paymentForms && paymentForms.realty_access) {
        return paymentForms.realty_access;
      }

      return null;
    }
  }
};
</script>

<style scoped>
.cabinet-pay-btn {
  width: 100%;
}
</style>
